define(['santa-components', 'react', 'lodash', 'components', 'componentsCore'], function (santaComponents, React, _, components, componentsCore) {
    'use strict';

    /**
     * @class components.PopupCloseTextButton
     * @extends {components.siteButton}
     */

    const shouldAddOnclick = function (compData) {
        const linkdata = compData.link;
        const linkType = _.get(linkdata, 'type');
        const linkTarget = _.get(linkdata, 'target');
        if (_.includes(['PageLink', 'DynamicPageLink', 'AnchorLink'], linkType) || linkType === 'ExternalLink' && linkTarget === '_self') { // eslint-disable-line no-mixed-operators
            return false;
        }
        return true;
    };

    class PopupCloseTextButton extends components.siteButton {
        render() {
            const element = super.render();
            const onClick = !shouldAddOnclick(this.props.compData) ? element.props.onClick : (...args) => {
                this.props.closePopupPage(...args);
                if (typeof element.props.onClick === 'function') {
                    return element.props.onClick(...args);
                }
            };
            return React.cloneElement(element, {
                role: 'button',
                tabIndex: '0',
                onClick,
                onKeyDown: (...args) => {
                    componentsCore.utils.accessibility.keyboardInteractions.activateBySpaceOrEnterButton(...args);
                    if (typeof element.props.onKeyDown === 'function') {
                        element.props.onKeyDown(...args);
                    }
                }
            });
        }
    }

    PopupCloseTextButton.propTypes = _.defaults({
        closePopupPage: santaComponents.santaTypesDefinitions.popupPage.close
    }, components.siteButton.propTypes);

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.PopupCloseTextButton', PopupCloseTextButton);

    return PopupCloseTextButton;
});
